// When adding new modules, they should be added in the same naming scheme as the existing ones:
// Key = uppercase component name without "CR"
export enum ContentModules {
  COVER = "CRCover",
  CARDS = "CRCards",
  CATEGORIES = "CRCategories",
  CHAPTER = "CRChapter",
  BENEFITS = "CRBenefits",
  FAQ = "CRFaq",
  HIRINGPROCESS = "CRHiringProcess",
  IMAGETEXT = "CRImageText",
  TEXT = "CRText",
  VIDEO = "CRVideo",
  GALLERY = "CRGallery",
}
